import { AppUser, DEFAULT_PAGE_PERMISSIONS, PagePermissions } from "../models/app-user";
import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { PrimaryRole } from "../models/primary-roles";

interface AppState {
    appUser: AppUser | null | undefined;
}

export const user: MemoizedSelector<AppState, AppUser | null | undefined> = createFeatureSelector("appUser");

export const acceptedEula: MemoizedSelector<AppState, boolean | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        return appUser ? appUser.acceptedEula : undefined;
    },
);
export const pagePermissions: MemoizedSelector<AppState, PagePermissions | null | undefined> = createSelector(
    user,
    (appUser: AppUser | null | undefined) => {
        if (!appUser) {
            // This will return either null or undefined (whatever appUser is)
            return appUser;
        }
        const pagePermissions: PagePermissions = { ...DEFAULT_PAGE_PERMISSIONS };
        if (appUser.primaryRole === PrimaryRole.UNKNOWN || !appUser.primaryRole) {
            return pagePermissions;
        }
        pagePermissions.domains = true;
        pagePermissions.logs = true;
        pagePermissions.tlsdiscovery = true;

        if (appUser.primaryRole === PrimaryRole.DOMAIN_VALIDATOR) {
            return pagePermissions;
        }
        pagePermissions.certificates = true;
        pagePermissions.manageCertificates =
            appUser.systemRoles.admin ||
            appUser.accountAnyRoles.admin ||
            appUser.organizationAnyRoles.admin ||
            appUser.organizationAnyRoles.requestor;
        pagePermissions.cas = true;
        pagePermissions.requests = true;
        pagePermissions.dashboard = true;
        pagePermissions.users =
            appUser.systemRoles.admin ||
            appUser.systemRoles.auditor ||
            appUser.accountAnyRoles.admin ||
            appUser.accountAnyRoles.auditor ||
            appUser.organizationAnyRoles.admin ||
            appUser.organizationAnyRoles.auditor;
        pagePermissions.admin = appUser.systemRoles.admin;

        return pagePermissions;
    },
);
